import { render, staticRenderFns } from "./videoDialog.vue?vue&type=template&id=7c88e56d&scoped=true&"
import script from "./videoDialog.vue?vue&type=script&lang=ts&"
export * from "./videoDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c88e56d",
  null
  
)

export default component.exports